<template>
    <span class="tag" :style="style">
        <slot></slot>
    </span>
</template>
<script>
export default {
    name: 'Tag',
    props: {
        color: String,
        background: { type: Boolean, default: true },
    },
    watch: {
        color: function () {
            this.updateStyle()
        },
        background: function () {
            this.updateStyle()
        },
    },
    data() {
        return { style: null }
    },
    created() {
        this.updateStyle()
    },
    methods: {
        updateStyle() {
            // color 只支持6位
            const c = this.color
            c.toLowerCase()
            var list = []
            for (var i = 1; i < 7; i += 2) {
                list.push(parseInt('0x' + c.slice(i, i + 2)))
            }
            const style = { color: this.color }
            if (this.background) {
                style.background = `rgba(${list.join(',')}, 0.15)`
            }
            this.style = style
        },
    },
}
</script>
<style lang="less" scoped>
.tag {
    height: 24px;
    padding: 0 8px;
    line-height: 24px;
    display: inline-block;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 700;
}
</style>
