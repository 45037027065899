<template>
    <el-tooltip effect="dark" :content="`${num}`" placement="top">
        <span>{{ val }}</span>
    </el-tooltip>
</template>
<script>
export default {
    name: 'NumFormat',
    props: ['num', 'float', 'unit'],
    data() {
        return {
            val: '',
        }
    },
    watch: {
        num: function () {
            this.updateVal()
        },
        float: function () {
            this.updateVal()
        },
        unit: function () {
            this.updateVal()
        },
    },
    created() {
        this.updateVal()
    },
    methods: {
        updateVal() {
            let num = this.num || 0
            let float = this.float
            const unitMap = {
                万: { val: 10000, name: 'w' },
                百万: { val: 10000 * 100, name: 'w' },
                千万: { val: 10000 * 1000, name: 'w' },
            }
            const activeUnit = unitMap[this.unit]
            if (activeUnit && activeUnit.val < num) {
                num = num / activeUnit.val
                float = 2
                this.val = this.numFormat(num, float) + activeUnit.name
            } else {
                this.val = this.numFormat(num, float)
            }
        },
        numFormat(num, float) {
            if (!num || isNaN(num)) return 0

            // float 最大为 text 长度
            let text = '0000000000'
            const f = float || float === 0 ? float : 2
            let n = typeof num == 'string' ? parseFloat(num) : num
            n = n.toFixed(f)
            n = parseFloat(n)
            n = n.toLocaleString()
            if (n.indexOf('.') === -1 && f) {
                n = n + '.' + text.slice(0, f)
            } else if (n.indexOf('.') !== -1 && n.split('.')[1].length === 1) {
                const len = n.split('.')[1].length
                n = n + text.slice(0, f - len)
            }
            return n
        },
    },
}
</script>
