// 过滤查询参数中的空值 空字符串
export const filterQueryEmpty = function (obj) {
    const newObj = {}
    for (const key in obj) {
        if (obj[key] || obj[key] === 0 || obj[key] === false) {
            newObj[key] = obj[key]
        }
    }
    return newObj
}

export const replaceUrl = function (msg) {
    msg = String(msg)
    return msg.replace(/(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g, (url) => {
        return `<a href='${url}' target='_blank'>${url}</a>`
    })
}

export const dataType = function (data, type) {
    return Object.prototype.toString.call(data) === `[object ${type}]`
}

export const dataRelation = function (mainData, secondData, mainKey, secondKey) {
    const obj = {}
    secondData.forEach((item) => {
        obj[item[secondKey]] = item
    })
    mainData = mainData.map((item) => {
        return {
            ...obj[item[mainKey]],
            ...item,
        }
    })
    return mainData
}

export const getMappingIds = function (list, mappingId) {
    return list.map((item) => item[mappingId])
}

export const mergeMappingData = function (mappingData, data, attr, mappingId) {
    const mapData = {}
    const list = []

    mappingData.forEach((item) => {
        mapData[item.id] = item
    })

    data.forEach((item) => {
        item[attr] = mapData[item[mappingId]]
        list.push(item)
    })
    return list
}

// 补0
export const supplementZero = function (num) {
    if (isNaN(num)) {
        num = 0
    }
    return num >= 10 ? num : `0${num}`
}

export const getAttendDurationText = function (time, type) {
    let date = parseInt(time)
    if (!date) return '-'
    if (type === 'ms') {
        date = parseInt(date / 1000)
    }
    const h = parseInt(date / 3600)
    const m = parseInt((date % 3600) / 60)

    let str = ''
    if (h !== 0) {
        str += h + '小时'
    }

    str += `${m}分`

    if (date < 60) {
        return `${date}秒`
    }

    return str
}

export const getImageWH = function (file) {
    return new Promise((resolve, reject) => {
        const imgUrl = window.URL.createObjectURL(file)
        const img = new Image()
        img.src = imgUrl
        img.onload = function () {
            resolve({
                width: img.width,
                height: img.height,
            })
        }
        img.onerror = function (err) {
            reject(err)
        }
    })
}

export const getPickerOptions = function (dayNums) {
    const options = []
    dayNums.forEach((item) => {
        options.push({
            text: item.text,
            onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * item.dayNum)
                picker.$emit('pick', [start, end])
            },
        })
    })
    return options
}

export const isMobile = function () {
    const UA = window.navigator.userAgent
    return /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(UA) ? true : false
}

export const generateColorFromString = function (str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    const r = (hash >> 16) & 200
    const g = (hash >> 8) & 200
    const b = hash & 200
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export const isEmptyArray = (val) => {
    if (!val || val.length === 0) {
        return true
    }
    return false
}

export const isUserId = function (val) {
    if (val >= 2147483647 || !/^\d+?$/.test(`${val}`)) {
        return false
    }
    return true
}

export const getVideoDuration = (url) => {
    return new Promise((reslove) => {
        let video = document.createElement('video')
        video.preload = 'metadata'
        video.src = url
        video.onloadedmetadata = () => {
            reslove(Math.round(video.duration))
            video = null
        }
    })
}

export const listObjectField = ({ list = [], key }) => {
    const _list = []
    list.forEach((i) => {
        if (!_list.includes(i[key])) {
            _list.push(i[key])
        }
    })
    return _list
}

export const listFormatMap = ({ list = [], key }) => {
    const _map = {}
    list.forEach((i) => {
        _map[i[key]] = i
    })
    return _map
}
