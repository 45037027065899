<template>
    <span v-if="staff" class="staff_name" :class="staff.state === 1 ? 'quit_staff' : ''">
        {{ staff.name || '-' }}
    </span>
    <span v-else>-</span>
</template>
<script>
export default {
    name: 'StaffName',
    props: ['staff'],
}
</script>
<style lang="less" scoped>
.quit_staff {
    color: #f00 !important;
}
</style>
