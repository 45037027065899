import Vue from 'vue'
import App from '@/App.vue'
import _config from '@/js/config'
import router from '@/router/index'
import store from '@/store/index'
import ElementUI from 'element-ui'
import StaffName from '@/components/staffName/index'
import Watermark from '@/components/watermark/index'
import ExportFile from '@/components/exportFile/index'
import NumFormat from '@/components/numFormat/index'
import MediaPreview from '@/components/mediaPreview/index'
import Tag from './components/tag/index'

import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Watermark)
Vue.use(StaffName)
Vue.use(ExportFile)
Vue.use(NumFormat)
Vue.use(Tag)
Vue.use(MediaPreview)
Vue.use(ElementUI, { size: 'small', zIndex: 3000 })

Vue.config.productionTip = false

const mixin = {
    methods: {
        P: function (val) {
            const _user = store.state.user.userInfo
            if (!_user) return false
            if (_user.isAdmin) return true
            const permissionIds = _user.permissionIds || []
            return permissionIds.includes(val)
        },
    },
}
store.dispatch('user/login').then(() => {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#root')
    Vue.mixin(mixin)
})

// 设置index.html 默认值
const _favicon = document.createElement('link')
_favicon.rel = 'icon'
_favicon.href = _config.favicon
document.head.appendChild(_favicon)
document.title = _config.title
