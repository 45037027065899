import { getCmsConfig } from '@/js/api/commonApi'

const state = {
    // 递增 versionCode，与 cupid_sys_conf -> hy_cms -> value 一致
    versionCode: 1,
    BEGONIA_HOST: '',
}

const mutations = {
    SET_CONFIG: (state, data) => {
        Object.keys(data).forEach((key) => {
            state[key] = data[key]
        })
    },
}

const actions = {
    storage(_, { key, val }) {
        const _key = key ? `global_localStorage_${key}` : ''
        let _val = val
        if (val) {
            window.localStorage.setItem(_key, val)
        } else if (key) {
            _val = window.localStorage.getItem(_key)
        }
        return _val
    },
    storageClear() {
        window.localStorage.clear()
    },
    async getCmsConfig({ commit }) {
        // 缓存配置
        const configHost = window.localStorage.getItem('user_localStorage_configHost')
        let _config = {}
        if (configHost) {
            _config = JSON.parse(configHost)
        } else {
            const data = await getCmsConfig()
            window.localStorage.setItem('user_localStorage_configHost', data.data)
            _config = JSON.parse(data.data)
        }
        const configMap = { BEGONIA_HOST: _config.begoniaHost }
        commit('SET_CONFIG', configMap)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
