import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const Login = () => import('../views/Login.vue')

const routes = [
    {
        path: '/',
        name: 'all',
        component: Login,
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import('../views/ResetPassword.vue'),
    },
    {
        path: '/talkLogin',
        name: 'talkLogin',
        component: () => import('../views/TalkLogin.vue'),
    },
    {
        path: '/talkDialog',
        name: 'talkDialog',
        component: () => import('../views/TalkDialog.vue'),
    },
    {
        path: '/weComAuth',
        name: 'weComAuth',
        component: () => import('../views/WeComAuth.vue'),
    },
    {
        path: '/mqData',
        name: 'mqData',
        component: () => import('../views/MqData.vue'),
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue'),
    },
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
})

export default router
