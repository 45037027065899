<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import { getProjectVersion } from '@/js/api/commonApi.js'

let _timer = null
export default {
    name: 'app',
    created() {
        // 5分钟查询一次项目版本
        _timer = setInterval(() => {
            this.getProjectVersionApi()
        }, 5 * 60 * 1000)
    },
    methods: {
        getProjectVersionApi() {
            const { versionCode } = this.$store.state.global
            getProjectVersion()
                .then((res) => {
                    const _code = parseInt(res.data.hy_cms)
                    console.log(`当前版本: ${versionCode}, 线上最新版本 ${_code}`)
                    if (_code > versionCode) {
                        this.$alert('为了不断提升用户体验并保障系统安全，我们将于近期推出一次更新。', '系统更新', {
                            confirmButtonText: '确定',
                            showClose: false,
                            callback: () => {
                                window.location.reload(true)
                            },
                        })
                        clearInterval(_timer)
                    }
                })
                .catch(() => {})
        },
    },
}
</script>

<style lang="less">
@import url('./css/reset.less');
#app {
    font-size: 14px;
}

.page_container {
    background: #ffffff;
    padding: 16px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    min-width: 900px;
}

.card_container {
    background: #ffffff;
    padding: 12px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination {
    margin: 12px auto 0;
    text-align: center;
}

.custom_statistic {
    width: auto !important;
    text-align: inherit !important;
    .con {
        display: inline-block !important;
        .number {
            font-size: 13px !important;
        }
    }
}
.el-drawer.rtl {
    .el-drawer__body {
        padding: 0 20px 20px;
    }
}
</style>
<style lang="less">
// 搜索框自定义UI
.query_header {
    .query_conditions {
        padding: 10px 0 0;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        .condition_item {
            flex-shrink: 0;
            width: 33.33%;
            padding: 0 12px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            box-sizing: border-box;
        }
        .condition_label {
            width: 80px;
            flex-shrink: 0;
            text-align: right;
            font-size: 14px;
            color: #606266;
            padding: 0 12px 0 0;
            box-sizing: border-box;
        }
        .condition_content {
            flex-grow: 1;
            > .el-select {
                width: 100%;
            }
            > .el-date-editor {
                width: 100%;
            }
            .el-cascader {
                display: block;
            }
        }
        .query_type {
            width: 100px;
        }
    }
    > .opt_container {
        text-align: right;
        padding: 10px 0;
        &::after {
            content: '';
            display: block;
            clear: both;
        }
        .float_left_btn {
            float: left;
            margin-left: 0;
            margin-right: 10px;
        }
    }
}

@media screen and (min-width: 1921px) {
    .query_header {
        .query_conditions {
            .condition_item {
                width: 25%;
            }
            .condition_label {
                width: 72px;
                padding: 0 8px 0 0;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .query_header {
        .query_conditions {
            .condition_item {
                width: 49.8%;
            }
            .condition_label {
                width: 72px;
                padding: 0 8px 0 0;
                font-size: 12px;
            }
        }
    }
}
</style>
<style lang="less">
.custom_drawer {
    #el-drawer__title {
        button,
        span {
            outline: none;
        }
    }
    .el-drawer__body {
        padding: 0 20px 80px !important;
        overflow: scroll;
    }
    .drawer_title {
        line-height: 24px;
        font-size: 14px;
        color: #303133;
        margin-bottom: 20px;
    }
    .drawer_footer {
        position: absolute;
        z-index: 2;
        height: 70px;
        background: #fff;
        left: 0;
        bottom: 0;
        border-top: 1px solid #eee;
        padding: 15px 0 15px 80px;
        width: 100%;
        box-sizing: border-box;
    }
}
</style>
<style lang="less">
.mini_link {
    .el-link {
        font-size: 12px;
    }
}
.upload_image {
    .el-icon-picture {
        font-size: 100px;
        color: #999999;
    }
    .el-image {
        width: 240px;
        height: 240px;
    }
    .auto_image {
        width: 280px;
        height: auto;
    }
}
</style>
