<template>
    <div class="watermark" id="watermark" :style="`background-image: url(${name}),url(${name}),url(${name});`"></div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Watermark',
    data() {
        return {
            name: '',
        }
    },
    computed: { ...mapGetters(['userInfo']) },
    created() {
        this.name = this.createWatermark()
    },
    methods: {
        createWatermark() {
            const _text = this.userInfo ? `${this.userInfo.username}-${this.userInfo.id}` : ''
            const _canvas = document.createElement('canvas')
            _canvas.setAttribute('width', 300)
            _canvas.setAttribute('height', 200)
            const _ctx = _canvas.getContext('2d')
            _canvas.style.display = 'none'

            _ctx.rotate((-10 * Math.PI) / 180)
            _ctx.font = '14px Arial'
            _ctx.fillStyle = '#000'
            _ctx.textAlign = 'center'
            _ctx.textBaseline = 'middle'
            _ctx.fillText(_text, 150, 100)
            return _canvas.toDataURL('image/png')
        },
    },
}
</script>
<style lang="less" scoped>
.watermark {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 999999;
    background-repeat: repeat;
    opacity: 0.1;
    background-position: 150px 100px, 0 0;
}
</style>
