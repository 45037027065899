import { post, post_form, hunter_post, hunter_post_form } from '../http'

export const staffLogin = (data) => {
    return post({ path: '/staff/login', data })
}

export const getStaffCurrent = (data) => {
    return post_form({ path: '/staff/current', data, isCatch: true })
}

export const modStaffPassword = (data) => {
    return post_form({ path: '/staff/modPassword', data })
}

export const getStaffList = (data, loadingState) => {
    return post({ path: '/staff/list', data, loading: { status: loadingState === false ? false : true, text: '' } })
}

export const getStaffDetailList = (data) => {
    return post({ path: '/staff/list/detail', data })
}

export const addStaffAccount = (data) => {
    return post({ path: '/staff/add', data })
}

export const modStaffInfo = (data) => {
    return post({ path: '/staff/mod', data })
}

export const delStaff = (data) => {
    return post_form({ path: '/staff/del', data })
}

export const adminModStaffPassword = (data) => {
    return post({ path: '/staff/mod', data })
}

export const queryStaffWxcp = (data) => {
    return post({ path: '/staff/queryWxcp', data })
}

export const unbindWxCp = (data) => {
    return post_form({ path: '/staff/unbindWxCp', data })
}

export const saveStaffGroup = (data) => {
    return post({ path: '/staff/group/save', data })
}
export const getStaffGroupList = (data) => {
    return post({ path: '/staff/group/list', data })
}
export const delStaffGroup = (data) => {
    return post_form({ path: '/staff/group/del', data })
}

export const saveGroupMemer = (data) => {
    return post({ path: '/staff/group/member/save', data })
}
export const getGroupMemerList = (data) => {
    return post({ path: '/staff/group/member/list', data })
}
export const delGroupMemer = (data) => {
    return post({ path: '/staff/group/member/del', data })
}

export const saveStaffRole = (data) => {
    return post({ path: '/staff/role/save', data })
}
export const getStaffRoleList = (data) => {
    return post({ path: '/staff/role/list', data })
}
export const delStaffRole = (data) => {
    return post_form({ path: '/staff/role/del', data })
}

export const saveRolePermission = (data) => {
    return post({ path: '/staff/role/permission/save', data })
}
export const getStaffPermissions = (data) => {
    return post({ path: '/staff/permissions', data })
}

export const staffBindWxCp = (data) => {
    return post_form({ path: '/staff/bindWxCp', data })
}

export const getCaptcha = (data) => {
    return post_form({ path: '/staff/getCaptcha', data })
}
export const resetPassword = (data) => {
    return post_form({ path: '/staff/resetPassword', data })
}

// 接量配置
export const saveStaffQrcode = (data) => {
    return hunter_post({ path: '/qrcode/opt/save', data })
}

export const getStaffQrcode = (data) => {
    return hunter_post({ path: '/qrcode/opt/list', data })
}

export const enableStaffQrcode = (data) => {
    return hunter_post_form({ path: '/qrcode/opt/enable', data })
}
export const removeStaffQrcode = (data) => {
    return hunter_post_form({ path: '/qrcode/opt/remove', data })
}
