<template>
    <div>
        <el-button @click="onDownloadExcel" type="primary">{{ title || '导出数据' }}</el-button>
        <form ref="downloadForm" :action="exportUrl" method="post" target="_blank">
            <input type="hidden" name="data" :value="downloadParams" />
        </form>
    </div>
</template>
<script>
import _config from '@/js/config.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    name: 'ExportFile',
    props: ['host', 'path', 'title'],
    data() {
        return {
            exportUrl: '',
            downloadParams: '',
        }
    },
    methods: {
        onDownloadExcel() {
            const hostMap = {
                begonia: `${_config.HOST}/begonia-cms`,
                hunter: `${_config.HOST}/hunter-cms`,
            }
            let exportUrl = `${hostMap[this.host]}${this.path}`
            this.$emit('before-export', (params, data) => {
                this.downloadParams = JSON.stringify(filterQueryEmpty(params))
                // url添加参数
                const urlData = { ...data }
                urlData.token = this.$store.state.user.userInfo.token
                const urlDataList = []
                Object.keys(urlData).forEach((item) => {
                    urlDataList.push(`${item}=${urlData[item]}`)
                })
                if (urlDataList.length !== 0) {
                    exportUrl += `?${urlDataList.join('&')}`
                }
                this.exportUrl = exportUrl
                this.$nextTick(() => {
                    this.$refs.downloadForm.submit()
                })
            })
        },
    },
}
</script>
