import { setUserRoute } from '@/router/auth'
import { getStaffCurrent } from '@/js/api/staffApi'
import { getProjectList, getProjectStaff } from '@/js/api/commonApi.js'

const state = {
    userInfo: null,
    projectList: [],
}

const mutations = {
    SET_ROUTER: () => {
        setUserRoute()
    },
    SET_USERINFO: (state, userInfo) => {
        let permissionIds = []
        const permissions = userInfo ? userInfo.permissions || [] : []
        permissions.forEach((item) => {
            permissionIds.push(item.id)
        })
        if (userInfo) {
            userInfo.permissionIds = permissionIds
        }
        if (userInfo.roles) {
            let _isAdmin = false
            userInfo.roles.forEach((i) => {
                if (i.roleNo == 'admin') {
                    _isAdmin = true
                }
            })
            userInfo.isAdmin = _isAdmin
        }
        state.userInfo = userInfo
    },

    SET_PROJECTLIST: (state, list) => {
        state.projectList = list
    },
}

const actions = {
    async login({ commit }, userInfo) {
        let _user = await this.dispatch('user/storage', { key: 'userInfo' })
        if (_user) _user = JSON.parse(_user)
        let user = userInfo || _user
        if (user) {
            this.dispatch('user/storage', { key: 'userInfo', val: JSON.stringify(user) })
            commit('SET_USERINFO', user)
            // 更新信息
            const newInfo = await getStaffCurrent({}).catch(() => {})
            if (newInfo) {
                this.dispatch('user/storage', { key: 'userInfo', val: JSON.stringify(newInfo.data) })
                commit('SET_USERINFO', newInfo.data)
                commit('SET_ROUTER')
                this.dispatch('user/getProjectList', user)
            } else {
                this.dispatch('user/logout')
            }
        }
    },
    async logout() {
        this.dispatch('user/storageClear').then(() => {
            window.location.reload()
        })
    },
    storage(_, { key, val }) {
        const _key = key ? `user_localStorage_${key}` : ''
        let _val = val
        if (val) {
            window.localStorage.setItem(_key, val)
        } else if (key) {
            _val = window.localStorage.getItem(_key)
        }

        return _val
    },
    storageClear() {
        const _keys = Object.keys(window.localStorage)
        _keys.forEach((key) => {
            if (key.indexOf('user_localStorage_') === 0) {
                window.localStorage.removeItem(key)
            }
        })
    },

    // 查询用户关联项目
    getProjectList({ commit }, userInfo) {
        Promise.all([getProjectStaff({ staffIds: [userInfo.id] }), getProjectList({ orderBy: '+id' })]).then((res) => {
            const _staffProject = res[0].data.map((i) => i.projectNo) || []
            const _list = []
            res[1].data.forEach((i) => {
                if (_staffProject.includes(i.projectNo)) {
                    _list.push(i)
                }
            })
            commit('SET_PROJECTLIST', _list)
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
