import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import global from './modules/global'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user,
        global,
    },
    getters: {
        userInfo: (state) => state.user.userInfo,
        projectList: (state) => state.user.projectList,
        BEGONIA_HOST: (state) => state.global.BEGONIA_HOST,
    },
})

export default store
