import axios from 'axios'
import _config from '@/js/config.js'
import { Message, Loading } from 'element-ui'
import qs from 'qs'
import store from '@/store/index'

const http = (params) => {
    const { method, postType, path, data, showError, isCatch, loading } = params
    return new Promise((resolve, reject) => {
        const headers = {
            ['content-Type']: 'application/json',
        }
        if (postType === 'form') {
            headers['content-Type'] = 'application/x-www-form-urlencoded'
        } else if (postType === 'data') {
            headers['content-Type'] = 'multipart/form-data'
        }
        const { userInfo } = store.state.user
        if (userInfo) {
            headers['token'] = userInfo.token
        }

        let _loading = null
        if (loading.status) {
            _loading = Loading.service({ text: loading.text || 'Loading', background: 'rgba(0, 0, 0, 0.2)' })
        }
        // console.log(_config.HOST, path)
        axios({
            method,
            url: _config.HOST + path,
            headers: headers,
            data,
            withCredentials: true,
        })
            .then((res) => {
                if (_loading) _loading.close()
                if (res.status == 200 && res.data.code === 200) {
                    resolve(res.data)
                } else if (res.data.code === 401) {
                    if (isCatch) {
                        reject(res)
                    } else {
                        Message.error('登录已过期，请重新登录')
                        setTimeout(() => {
                            store.dispatch('user/logout')
                        }, 1000)
                    }
                } else {
                    if (showError) Message.error(res.data.message)
                    if (isCatch) reject(res)
                }
            })
            .catch((err) => {
                if (showError) Message.error(JSON.stringify(err))
                if (isCatch) reject(err)
                if (_loading) _loading.close()
            })
    })
}

export const get = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'GET',
        postType: '',
        path: `/begonia-cms${path}`,
        data,
        isCatch,
        showError,
        loading,
    })
}

export const post = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: '',
        path: `/begonia-cms${path}`,
        data,
        isCatch,
        showError,
        loading,
    })
}

export const post_form = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: 'form',
        path: `/begonia-cms${path}`,
        data: qs.stringify(data),
        isCatch,
        showError,
        loading,
    })
}

export const cupid_post_form = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: 'form',
        path: `/cupid-api${path}`,
        data: qs.stringify(data),
        isCatch,
        showError,
        loading,
    })
}

export const cupid_post = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: '',
        path: `/cupid-api${path}`,
        data,
        isCatch,
        showError,
        loading,
    })
}

export const hunter_post_form = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: 'form',
        path: `/hunter-cms${path}`,
        data: qs.stringify(data),
        isCatch,
        showError,
        loading,
    })
}

export const hunter_post = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: '',
        path: `/hunter-cms${path}`,
        data,
        isCatch,
        showError,
        loading,
    })
}

export const walle_post_form = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: 'form',
        path: `/walle-api${path}`,
        data: qs.stringify(data),
        isCatch,
        showError,
        loading,
    })
}

export const walle_post = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: '',
        path: `/walle-api${path}`,
        data,
        isCatch,
        showError,
        loading,
    })
}

export const begonia_post_form = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params

    return http({
        method: 'POST',
        postType: 'form',
        path: `/begonia-api${path}`,
        data: qs.stringify(data),
        isCatch,
        showError,
        loading,
    })
}

export const begonia_post = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: '',
        path: `/begonia-api${path}`,
        data,
        isCatch,
        showError,
        loading,
    })
}

export const dipper_post_form = (params) => {
    const { path, data, isCatch, showError = true, loading = { status: true, text: '' } } = params
    return http({
        method: 'POST',
        postType: 'form',
        path: `/dipper${path}`,
        data: qs.stringify(data),
        isCatch,
        showError,
        loading,
    })
}
