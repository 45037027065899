import { cupid_post_form, post, post_form, hunter_post_form } from '../http'

export const getCmsConfig = () => {
    return cupid_post_form({ path: '/config/get', data: { key: 'server_host' } })
}

export const getProjectList = (data) => {
    return post({ path: '/app/project/list', data })
}
export const saveProjectInfo = (data) => {
    return post({ path: '/app/project/save', data })
}
export const delProject = (data) => {
    return post_form({ path: '/app/project/del', data })
}

export const saveProjectStaff = (data) => {
    return post({ path: '/app/project/rel/save', data })
}
export const getProjectStaff = (data) => {
    return post({ path: '/app/project/rel/list', data })
}
export const addProjectStaff = (data) => {
    return post({ path: '/app/project/rel/add', data })
}
export const delProjectStaff = (data) => {
    return post({ path: '/app/project/rel/del', data })
}

export const getOssRamToken = (data) => {
    return post_form({ path: '/app/aliyun/getRamToken', data })
}

export const userTradeRank = (data) => {
    return hunter_post_form({ path: '/oppo/userTradeRank', data })
}

export const saveSystemConfig = (data) => {
    return post_form({ path: '/app/config/system/save', data })
}

export const getSystemConfig = (data) => {
    return post_form({ path: '/app/config/system/get', data })
}

export const getProjectVersion = () => {
    return post_form({
        path: '/app/config/system/get',
        data: { func: 'version', key: 'hy_cms' },
        loading: { status: false },
        isCatch: true,
    })
}
