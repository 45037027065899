<template>
    <el-dialog
        :title="mediaInfo && mediaInfo.title"
        width="720px"
        :visible.sync="isShow"
        :before-close="onClose"
        :append-to-body="true"
    >
        <div v-if="mediaInfo">
            <div v-if="['video', 'audio'].includes(mediaInfo.type)" :id="mediaId"></div>
            <iframe :src="mediaInfo.url + '#toolbar=0'" v-else-if="mediaInfo.type === 'pdf'">
                This browser does not support PDFs. Please download the PDF to view it:
                <a :href="mediaInfo.url">Download PDF</a>
            </iframe>
        </div>
    </el-dialog>
</template>
<script>
import Player from 'xgplayer'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'MediaPreview',
    props: ['mediaInfo'],
    data() {
        return {
            isShow: false,
            mediaId: uuidv4(),
        }
    },
    watch: {
        mediaInfo: function () {
            if (this.mediaInfo) {
                this.isShow = true
                this.onInit()
            } else {
                this.isShow = false
            }
        },
    },
    methods: {
        onClose() {
            this.isShow = false
            if (this.mediaPlayer && this.mediaPlayer.destroy) {
                this.mediaPlayer.destroy()
                this.mediaPlayer = null
            }
        },
        onInit() {
            this.mediaId = uuidv4()
            this.$nextTick(() => {
                if (['video', 'audio'].includes(this.mediaInfo.type)) {
                    this.mediaPlayer = new Player({
                        id: this.mediaId,
                        url: this.mediaInfo.url,
                        fluid: true,
                        playbackRate: [0.5, 0.75, 1, 1.5, 2],
                        lang: 'zh-cn',
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding: 10px 20px 30px;
}
iframe {
    width: 100%;
    height: 640px;
    display: block;
}
</style>
