import router from './index'
import store from '@/store/index'
import { Message } from 'element-ui'

export const menuRouters = [
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/pages/index/Index.vue'),
        meta: { id: 100, title: '首页', icon: 'el-icon-s-home', menu: true },
    },
    {
        path: '/advert',
        name: 'advert',
        component: () => import('../views/pages/advert/Index.vue'),
        meta: { id: 101, title: '投放', icon: 'el-icon-data-board', menu: true },
        children: [
            {
                path: '/advert/provider',
                name: 'advertProvider',
                component: () => import('../views/pages/advert/Provider.vue'),
                meta: { id: 10101, menu: true, title: '渠道' },
            },
            // {
            //     path: '/advert/website',
            //     name: 'advertWebsite',
            //     component: () => import('../views/pages/advert/Website.vue'),
            //     meta: { id: 10102, menu: true, title: '落地页' },
            // },
            // {
            //     path: '/advert/merchantQrcode',
            //     name: 'advertMerchantQrcode',
            //     component: () => import('../views/pages/advert/merchantQrcode/Index.vue'),
            //     meta: { id: 10103, menu: true, title: '活码' },
            //     children: [
            //         {
            //             path: '/advert/merchantQrcode/list',
            //             name: 'advertMerchantQrcodeList',
            //             component: () => import('../views/pages/advert/merchantQrcode/List.vue'),
            //             meta: { id: 1010301, menu: true, title: '活码管理' },
            //         },
            //         {
            //             path: '/advert/merchantQrcode/data',
            //             name: 'advertMerchantQrcodeData',
            //             component: () => import('../views/pages/advert/merchantQrcode/Data.vue'),
            //             meta: { id: 1010302, menu: true, title: '扫码记录' },
            //         },
            //     ],
            // },
            {
                path: '/advert/form',
                name: 'advertForm',
                component: () => import('../views/pages/advert/Form.vue'),
                meta: { id: 10104, menu: true, title: '表单' },
            },
            {
                path: '/advert/express',
                name: 'advertExpress',
                component: () => import('../views/pages/advert/Express.vue'),
                meta: { id: 10106, menu: true, title: '快递' },
            },
            {
                path: '/advert/cost',
                name: 'advertCost',
                component: () => import('../views/pages/advert/Cost.vue'),
                meta: { id: 10105, menu: true, title: '成本' },
            },
        ],
    },
    {
        path: '/sale',
        name: 'sale',
        component: () => import('../views/pages/sale/Index.vue'),
        meta: { id: 102, title: '销售', icon: 'el-icon-shopping-bag-2', menu: true },
        children: [
            {
                path: '/sale/oppoConfig',
                name: 'saleOppoConfig',
                component: () => import('../views/pages/sale/OppoConfig.vue'),
                meta: { id: 10201, menu: true, title: '机会分配' },
            },
            {
                path: '/sale/oppoList',
                name: 'saleOppoList',
                component: () => import('../views/pages/sale/OppoList.vue'),
                meta: { id: 10203, menu: true, title: '机会管理' },
            },
            {
                path: '/sale/intentionList',
                name: 'saleIntentionList',
                component: () => import('../views/pages/sale/IntentionList.vue'),
                meta: { id: 10204, menu: true, title: '意向管理' },
            },
            {
                path: '/sale/workbench',
                name: 'saleWorkbench',
                component: () => import('../views/pages/sale/workbench/Index.vue'),
                meta: { id: 10202, menu: true, title: '工作台' },
                children: [
                    {
                        path: '/sale/workbench/tel',
                        name: 'saleWorkbenchTel',
                        component: () => import('../views/pages/sale/workbench/Tel.vue'),
                        meta: { id: 1020201, menu: true, title: '运营' },
                    },
                    // {
                    //     path: '/sale/workbench/kol',
                    //     name: 'saleWorkbenchKol',
                    //     component: () => import('../views/pages/sale/workbench/Kol.vue'),
                    //     meta: { id: 1020202, menu: true, title: '社群' },
                    // },
                    {
                        path: '/sale/workbench/intention',
                        name: 'saleIntention',
                        component: () => import('../views/pages/sale/workbench/Intention.vue'),
                        meta: { id: 1020203, menu: true, title: '意向' },
                    },
                ],
            },
        ],
    },
    {
        path: '/course',
        name: 'course',
        component: () => import('../views/pages/course/Index.vue'),
        meta: { id: 103, title: '课程', icon: 'el-icon-notebook-1', menu: true },
        children: [
            {
                path: '/course/publicCourse',
                name: 'publicCourse',
                component: () => import('../views/pages/course/PublicCourse.vue'),
                meta: { id: 10301, menu: true, title: '公开课' },
            },
            // {
            //     path: '/course/packageCourse',
            //     name: 'packageCourse',
            //     component: () => import('../views/pages/course/PackageCourse.vue'),
            //     meta: { id: 10302, menu: true, title: '课程包' },
            // },
            // {
            //     path: '/course/courseUnit',
            //     name: 'courseUnit',
            //     component: () => import('../views/pages/course/CourseUnit.vue'),
            //     meta: { id: 1003, menu: true, title: '课程单元' },
            // },
            // {
            //     path: '/course/articleList',
            //     name: 'courseArticleList',
            //     component: () => import('../views/pages/course/ArticleList.vue'),
            //     meta: { id: 10304, menu: true, title: '图文课程' },
            // },
            {
                path: '/course/liveCalendar',
                name: 'courseLiveCalendar',
                component: () => import('../views/pages/course/LiveCalendar.vue'),
                meta: { id: 10305, menu: true, title: '直播课表' },
            },
            {
                path: '/course/teacher',
                name: 'courseTeacher',
                component: () => import('../views/pages/course/Teacher.vue'),
                meta: { id: 10306, menu: true, title: '讲师管理' },
            },
            {
                path: '/course/mirageTpl',
                name: 'courseMirageTpl',
                component: () => import('../views/pages/course/MirageTpl.vue'),
                meta: { id: 10307, menu: true, title: '伪直播模版' },
            },
        ],
    },
    {
        path: '/shop',
        name: 'shop',
        component: () => import('../views/pages/shop/Index.vue'),
        meta: { id: 104, title: '商城', icon: 'el-icon-shopping-cart-1', menu: true },
        children: [
            {
                path: '/shop/product',
                name: 'shopProduct',
                component: () => import('../views/pages/shop/Product.vue'),
                meta: { id: 10401, menu: true, title: '商品管理' },
            },
            {
                path: '/shop/order',
                name: 'storeOrder',
                component: () => import('../views/pages/shop/Order.vue'),
                meta: { id: 10402, menu: true, title: '订单列表' },
            },
            // {
            //     path: '/shop/merchant',
            //     name: 'shopMerchant',
            //     component: () => import('../views/pages/shop/Merchant.vue'),
            //     meta: { menu: true, title: '商户配置' },
            // },
        ],
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/pages/user/Index.vue'),
        meta: { id: 105, menu: true, title: '用户', icon: 'el-icon-user' },
        children: [
            {
                path: '/user/list',
                name: 'userList',
                component: () => import('../views/pages/user/UserList.vue'),
                meta: { id: 10501, menu: true, title: '用户列表' },
            },
            {
                path: '/user/paidList',
                name: 'userPaidList',
                component: () => import('../views/pages/user/PaidList.vue'),
                meta: { id: 10502, menu: true, title: '付费学员' },
            },
            // {
            //     path: '/user/refund',
            //     name: 'userRefund',
            //     component: () => import('../views/pages/user/Refund.vue'),
            //     meta: { id: 10503, menu: true, title: '退费处理' },
            // },
        ],
    },
    // {
    //     path: '/paper',
    //     name: 'paper',
    //     component: () => import('../views/pages/paper/Index.vue'),
    //     meta: { id: 106, title: '作业问卷', icon: 'el-icon-notebook-2', menu: true },
    //     children: [
    //         {
    //             path: '/paper/homework',
    //             name: 'paperHomework',
    //             component: () => import('../views/pages/paper/Homework.vue'),
    //             meta: { id: 10601, menu: true, title: '课后作业' },
    //         },
    //         {
    //             path: '/paper/questionnaire',
    //             name: 'paperQuestionnaire',
    //             component: () => import('../views/pages/paper/Questionnaire.vue'),
    //             meta: { id: 1060102, menu: true, title: '问卷调查' },
    //         },
    //     ],
    // },
    {
        path: '/statement',
        name: 'statement',
        component: () => import('../views/pages/statement/Index.vue'),
        meta: { id: 107, title: '报表', icon: 'el-icon-data-analysis', menu: true },
        children: [
            {
                path: '/statement/management',
                name: 'productManagement',
                component: () => import('../views/pages/statement/Management.vue'),
                meta: { id: 10701, menu: true, title: '经营报表' },
            },
            {
                path: '/statement/ad',
                name: 'productAd',
                component: () => import('../views/pages/statement/Advert.vue'),
                meta: { id: 10702, menu: true, title: '投放报表' },
            },
            // {
            //     path: '/statement/telemarket',
            //     name: 'productTelemarket',
            //     component: () => import('../views/pages/statement/Telemarket.vue'),
            //     meta: { id: 10703, menu: true, title: '运营报表' },
            // },
            {
                path: '/statement/sale',
                name: 'productSale',
                component: () => import('../views/pages/statement/Sale.vue'),
                meta: { id: 10704, menu: true, title: '销售报表' },
            },
            // {
            //     path: '/statement/publicCourse',
            //     name: 'productPublicCourse',
            //     component: () => import('../views/pages/statement/PublicCourse.vue'),
            //     meta: { id: 10705, menu: true, title: '公开课转化' },
            // },
        ],
    },
    {
        path: '/system',
        name: 'system',
        component: () => import('../views/pages/system/Index.vue'),
        meta: { id: 108, title: '系统管理', icon: 'el-icon-setting', menu: true },
        children: [
            {
                path: '/system/project',
                name: 'projectList',
                component: () => import('../views/pages/system/ProjectList.vue'),
                meta: { id: 10801, menu: true, title: '项目管理' },
            },
            {
                path: '/system/list',
                name: 'staffList',
                component: () => import('../views/pages/system/StaffList.vue'),
                meta: { id: 10802, menu: true, title: '账号管理' },
            },
            {
                path: '/system/role',
                name: 'staffRole',
                component: () => import('../views/pages/system/RoleList.vue'),
                meta: { id: 10803, menu: true, title: '账号角色' },
            },
            {
                path: '/system/configure',
                name: 'staffConfigure',
                component: () => import('../views/pages/system/Configure.vue'),
                meta: { id: 10804, menu: true, title: '系统配置' },
            },
        ],
    },
]

const getRouter = function (permissionIds, routers, isAdmin) {
    const list = []
    routers.forEach((item) => {
        if (permissionIds.includes(item.meta.id) || isAdmin) {
            item.meta.subRouter = false
            item.children = getRouter(permissionIds, item.children || [], isAdmin)
            if (item.children.length !== 0) {
                item.redirect = item.children['0']['path']
                item.meta.subRouter = true
            }
            list.push(item)
        }
    })
    return list
}

export const getUserRouter = function () {
    const _user = store.state.user.userInfo
    const permissionIds = _user ? _user.permissionIds : []
    return getRouter(permissionIds, menuRouters, _user.isAdmin)
}

export const setUserRoute = () => {
    const routerList = getUserRouter()
    if (routerList.length === 0) {
        Message.error({
            duration: 1000,
            message: '当前账号暂无操作权限，请联系管理员配置',
            onClose: () => {
                store.dispatch('user/logout')
            },
        })
    } else {
        let redirect = routerList[0]['path']
        routerList.forEach((item) => {
            if (item.name === 'index') {
                redirect = item.path
            }
        })

        router.addRoutes([
            {
                path: '/content',
                name: 'content',
                component: () => import('../views/Content.vue'),
                redirect: redirect,
                children: routerList,
            },
        ])
    }
}
