let _HOST = '//api.hanyui.fun'
// let _HOST = '//api.dev.peki.vip'

console.log(_HOST)

export default {
    HOST: _HOST,
    title: '立志不凡教育',
    // 企业微信ID
    weComAppId: 'ww45bc7db5444027b1',
    weComAgentid: '1000007',
    // 公众号ID
    wxAppid: 'wxc20953a9605478f4',

    // 企业logo
    logo16_9: '/image/hanyu16_9.png',
    loginBg: '/image/login_bg4.jpg',
    // TODO
    botPreview: '/image/bot_preview.png',
    // favicon
    favicon: '/favicon.ico',

    // 文件模版链接 (接入时，注意调整模版中数据)
    formExcelTemplate: '/file/表单上传模板.xlsx',
    dyFormExcelTemplate: '/file/抖店直播带货模板.xlsx',
    costExcelTemplate: '/file/成本上传模板.xlsx',
    expressExcelTemplate: '/file/快递上传模板.xlsx',

    qrcodeTemplate1: 'https://res.peki.vip/20230719/f7208539b3ee4886b7635a1b04e010a1.png',
}
